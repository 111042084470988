import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import PhotoLeft from "../components/photo-left";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-html-parser";
import uuid4 from "uuid4";

const PriceGuidePage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "price-guide" }) {
				seoFieldGroups {
					metaTitle
					metaDescription
					localBusinessSchema
					openGraphDescription
					openGraphTitle
					productSchema
					image {
						node {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
				priceGuideFieldGroups {
					priceGuideIntroSection {
						heading
						subHeading
						description
					}
					priceGuideTwoColumnSectionWithCta {
						heading
						content
						cta {
							title
							target
							url
						}
						image {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					pricingSectionWithNotice {
						notice
						plans {
							heading
							notice
							plan {
								adultPrice
								childPrice
								heading
							}
						}
					}
					priceGuideTextSection {
						heading
						content
					}
					priceGuidePricingSection {
						heading
						plans {
							heading
							plan {
								heading
								childPrice
								adultPrice
							}
						}
					}
					priceGuideTwoColumnSection {
						heading
						content
						image {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					priceGuideCtaSectionWithHeading {
						heading
						subHeading
						cta {
							title
							target
							url
						}
					}
					priceGuideTwoColumnImageSection {
						leftImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						rightImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFieldGroups, priceGuideFieldGroups },
	} = data;

	const {
		priceGuideIntroSection,
		priceGuideTwoColumnSectionWithCta,
		pricingSectionWithNotice,
		priceGuideTextSection,
		priceGuidePricingSection,
		priceGuideTwoColumnSection,
		priceGuideCtaSectionWithHeading,
		priceGuideTwoColumnImageSection,
	} = priceGuideFieldGroups;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Price Guide",
				item: {
					url: `${siteUrl}/price-guide`,
					id: `${siteUrl}/price-guide`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/price-guide`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{priceGuideIntroSection &&
					!checkPropertiesForNull(priceGuideIntroSection, ["heading"]) && (
						<section className="pt-md-8 pt-5 ">
							<Container>
								<Row>
									<Col className="text-center">
										<p className="text-text-secondary jost-bold fs-6">
											{priceGuideIntroSection?.subHeading}
										</p>
										<h1 className=" display-4 pb-4">
											{priceGuideIntroSection?.heading}
										</h1>
										<div className="pb-4">
											<SafeHtmlParser
												htmlContent={priceGuideIntroSection?.description}
											/>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{priceGuideTwoColumnSectionWithCta &&
					!checkPropertiesForNull(priceGuideTwoColumnSectionWithCta, [
						"heading",
					]) && (
						<section>
							<PhotoLeft
								imgHeight="55rem"
								img={
									priceGuideTwoColumnSectionWithCta.image.node?.localFile
										.childImageSharp.gatsbyImageData
								}
								imgAlt={priceGuideTwoColumnSectionWithCta.image?.node?.altText}
								text={
									<div className="text-white text-center text-lg-start">
										<h2 className="   display-4 pb-4">
											{priceGuideTwoColumnSectionWithCta?.heading}
										</h2>
										<GatsbyImage
											className="mb-4 w-100 d-lg-none"
											image={
												priceGuideTwoColumnSectionWithCta.image.node?.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={
												priceGuideTwoColumnSectionWithCta.image?.node?.altText
											}
										/>

										<SafeHtmlParser
											htmlContent={priceGuideTwoColumnSectionWithCta?.content}
										/>

										{priceGuideTwoColumnSectionWithCta.cta &&
											priceGuideTwoColumnSectionWithCta.cta?.url && (
												<div>
													<Button
														className="text-white mt-lg-4 py-3 px-5 w-100 w-md-auto"
														variant="accent"
														as={Link}
														to={priceGuideTwoColumnSectionWithCta.cta?.url}
														target={
															priceGuideTwoColumnSectionWithCta.cta?.target
														}
													>
														{priceGuideTwoColumnSectionWithCta.cta?.title}
													</Button>
												</div>
											)}
									</div>
								}
								bgColour="bg-primary"
							/>
						</section>
					)}
				{pricingSectionWithNotice &&
					!checkPropertiesForNull(pricingSectionWithNotice, ["plans"]) && (
						<section className="py-5 py-lg-7">
							<Container>
								<Row className="justify-content-center">
									<Col lg={10}>
										<p className="text-center jost-bold pb-4 fs-5">
											<SafeHtmlParser
												htmlContent={pricingSectionWithNotice?.notice}
											/>
										</p>
									</Col>
								</Row>
							</Container>
							{pricingSectionWithNotice.plans &&
								pricingSectionWithNotice.plans.length > 0 && (
									<>
										{pricingSectionWithNotice.plans.map((item, i) => (
											<div key={uuid4()}>
												<Container>
													<Row
														className={`justify-content-center ${
															i !== 0 ? "mt-5" : ""
														}`}
													>
														<Col lg={10}>
															<h2>{item?.heading}</h2>
														</Col>
													</Row>
												</Container>
												<hr
													style={{ opacity: 1, height: "3px" }}
													className="bg-accent"
												/>
												{item.plan && item.plan.length > 0 && (
													<Container>
														<Row className="justify-content-center">
															<Col lg={10}>
																{/* {i === 0 && (
                                  <Row
                                    Row
                                    className="align-items-center justify-content-end"
                                  >
                                    <Col xs={2} className="text-end">
                                      <p className="jost-bold text-accent">
                                        Child
                                      </p>
                                    </Col>
                                    <Col xs={2} className="text-end">
                                      <p className="jost-bold text-accent">
                                        Adult
                                      </p>
                                    </Col>
                                  </Row>
                                )} */}
																{item.plan.map((planItem) => (
																	<Row
																		className="align-items-center"
																		key={uuid4()}
																	>
																		<Col xs={true}>
																			<p className="jost-medium">
																				{planItem?.heading}
																			</p>
																		</Col>
																		<Col xs={2} className="text-end">
																			<p className="jost-bold">
																				{planItem?.childPrice}
																			</p>
																		</Col>
																		<Col xs={2} className="text-end">
																			<p className="jost-bold">
																				{planItem?.adultPrice}
																			</p>
																		</Col>
																	</Row>
																))}
															</Col>
														</Row>
													</Container>
												)}
											</div>
										))}
									</>
								)}
						</section>
					)}

				{priceGuideTextSection &&
					!checkPropertiesForNull(priceGuideTextSection, ["plans"]) && (
						<section className="bg-primary py-5 py-lg-7">
							<Container>
								<Row>
									<Col className="text-center">
										<h2 className=" text-white fs-1 mb-5 ">
											{priceGuideTextSection?.heading}
										</h2>
										<div className="content-white">
											<SafeHtmlParser
												htmlContent={priceGuideTextSection?.content}
											/>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{priceGuidePricingSection &&
					!checkPropertiesForNull(priceGuidePricingSection, ["plans"]) && (
						<section>
							{priceGuidePricingSection.map((item, i) => (
								<div key={uuid4()}>
									<Container>
										<Row className="justify-content-center mt-5">
											<Col lg={10}>
												<h2>{item?.heading}</h2>
											</Col>
										</Row>
									</Container>
									<hr
										style={{ opacity: 1, height: "3px" }}
										className="bg-accent"
									/>
									{item.plans && item.plans.length > 0 && (
										<Container>
											<Row className="justify-content-center">
												{item.plans.map((planItem) => (
													<Col lg={10} className="mt-4">
														{/* {i !== 0 && (
															<Row className="align-items-center justify-content-end">
																<Col xs={2} className="text-end">
																	<p className="jost-bold text-accent">Child</p>
																</Col>
																<Col xs={2} className="text-end">
																	<p className="jost-bold text-accent">Adult</p>
																</Col>
															</Row>
														)} */}
														{planItem.heading && (
															<Row className="align-items-center">
																<Col xs={true}>
																	<p className="jost-italic">
																		{planItem?.heading}
																	</p>
																</Col>
															</Row>
														)}
														{planItem.plan && planItem.plan.length > 0 && (
															<>
																{planItem.plan.map((planDetail) => (
																	<Row className="align-items-center">
																		<Col xs={true}>
																			<p className="jost-medium">
																				{planDetail?.heading}
																			</p>
																		</Col>
																		{planDetail?.childPrice && (
																			<Col xs={2} className="text-end">
																				<p className="jost-bold">
																					{planDetail?.childPrice}
																				</p>
																			</Col>
																		)}
																		<Col xs={2} className="text-end">
																			<p className="jost-bold">
																				{planDetail?.adultPrice}
																			</p>
																		</Col>
																	</Row>
																))}
															</>
														)}
													</Col>
												))}
											</Row>
										</Container>
									)}
								</div>
							))}
						</section>
					)}
				{priceGuideTwoColumnSection &&
					!checkPropertiesForNull(priceGuideTwoColumnSection, ["heading"]) && (
						<section className="py-8">
							{" "}
							<PhotoLeft
								img={
									priceGuideTwoColumnSection?.image.node?.localFile
										.childImageSharp.gatsbyImageData
								}
								imgAlt={priceGuideTwoColumnSection?.image.node?.altText}
								text={
									<div>
										<h2 className=" display-4 pb-4">
											{priceGuideTwoColumnSection?.heading}
										</h2>
										<GatsbyImage
											className="mb-4 w-100 d-lg-none"
											image={
												priceGuideTwoColumnSection?.image.node?.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={priceGuideTwoColumnSection?.image.node?.altText}
										/>
										<div className="photo-left-content">
											<SafeHtmlParser
												htmlContent={priceGuideTwoColumnSection?.content}
											/>
										</div>
									</div>
								}
								bgColour="bg-secondary"
							/>
						</section>
					)}
				{priceGuideCtaSectionWithHeading &&
					!checkPropertiesForNull(priceGuideCtaSectionWithHeading, [
						"heading",
					]) && (
						<section>
							<Container>
								{" "}
								<Row>
									<Col className="text-center mb-10">
										<p className="text-text-secondary jost-bold fs-6">
											{priceGuideCtaSectionWithHeading?.subHeading}
										</p>
										<h2 className=" display-4 pb-4">
											{priceGuideCtaSectionWithHeading?.heading}
										</h2>
										{priceGuideCtaSectionWithHeading.cta &&
											priceGuideCtaSectionWithHeading.cta.url && (
												<Button
													className="text-white py-2 px-5 w-100 w-md-auto"
													variant="accent"
													as={Link}
													to={priceGuideCtaSectionWithHeading.cta?.url}
													target={
														priceGuideCtaSectionWithHeading?.cta?.target ??
														"_blank"
													}
												>
													{priceGuideCtaSectionWithHeading?.cta?.title}
												</Button>
											)}
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{priceGuideTwoColumnImageSection &&
					!checkPropertiesForNull(priceGuideTwoColumnImageSection, [
						"leftImage",
					]) && (
						<section
							style={{ height: "80vh" }}
							className="position-relative w-100 d-none d-lg-block"
						>
							{priceGuideTwoColumnImageSection?.leftImage && (
								<div className="h-100 position-absolute start-0 main-image-left">
									<GatsbyImage
										className="h-100 w-100"
										image={
											priceGuideTwoColumnImageSection?.leftImage.node?.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={
											priceGuideTwoColumnImageSection?.leftImage.node?.altText
										}
									/>
								</div>
							)}
							{priceGuideTwoColumnImageSection?.rightImage && (
								<div className="h-100 position-absolute end-0 main-image-right">
									<GatsbyImage
										className="h-100 w-100"
										image={
											priceGuideTwoColumnImageSection?.rightImage.node
												?.localFile.childImageSharp.gatsbyImageData
										}
										alt={
											priceGuideTwoColumnImageSection?.rightImage.node?.altText
										}
									/>
								</div>
							)}
						</section>
					)}
			</Layout>
		</>
	);
};

export default PriceGuidePage;
